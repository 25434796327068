/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 function getNavWidth() {
   var width = jQuery(window).width();
   var navPercentage;

   if (width >= 1500) {
     navPercentage = "100%";
   } else if (width >= 1000) {
     navPercentage = "100%";
   } else if (width >= 800) {
     navPercentage = "100%";
   } else if (width >= 400) {
     navPercentage = "100%";
   }else {
     navPercentage = "100%";
   }

   return navPercentage;
 }

 function openNav() {
   jQuery("#mySidenav").slideDown(500);
}

function closeNav() {
  jQuery("#mySidenav").slideUp(500);
}

function ajax_add_to_cart(event, productId, quantity, nameOfItems) {
  // copied from the plugin JS code, and needed to run the ajax update whenever you first add that product to the cart
  // (the plugin automatically does it for ALL products on page load, which is horifically slow and totally unnecessary)
  function ajax_update_option(optionChanged, callback) {
    //Disable cart button and options
    $('form.cart button').prop("disabled", true);
    $('form.cart button').css('opacity', '0.5');
    $('.product-option, .variations').find('input, select, textarea').prop("disabled", true);
    $('.product-option').find('input, select, textarea').css('opacity', '0.5');
    $('.product-option').find('input, select, textarea').css('opacity', '0.5');
    $('.variations').find('.value, .product_value, .product-value').css('opacity', '0.5');
    //Accordion
    var productOptions = optionChanged.closest('.product-options');
    if (productOptions.length > 0 && productOptions.find('.accordion-keep-open-on-select').val() == '' && productOptions.find('.accordion-expand').length > 0) {
        optionChanged.closest('.product-option').find('.product-option-accordion-content').hide(500);
        optionChanged.closest('.product-option').find('.accordion-expand').html('+');
    }
    //Price
    var summary = optionChanged.closest('.summary, .main-data, .product-info, li.product');
    var amountToUpdate = summary.find('.price');
    if (summary.find('.variations').length > 0) {
        amountToUpdate = summary.find('.single-variation, .single_variation');
    }
    if (amountToUpdate.length == 0) {
        if ($('.price').length == 1) {
            amountToUpdate = $('.price');
        }
    }
    var optionPriceToUpdate = optionChanged.closest('.product-option').find('.current-price');
    var productOption = optionChanged.val();
    if (optionChanged.closest('.product-option').find('input[type=checkbox].radio-image-radio').length > 0) {
        productOption = [];
        optionChanged.closest('.product-option').find('input[type=checkbox]:checked.radio-image-radio').each(function () {
            productOption.push($(this).val());
        });
    }
    if (optionChanged.closest('.product-option').find('input[type=checkbox].product-option-checkbox').length > 0) {
        productOption = '';
        if (optionChanged.closest('.product-option').find('input[type=checkbox].product-option-checkbox:checked').length > 0) {
            productOption = 'yes';
        }
    }
    var checkboxes = optionChanged.closest('.product-option').find('.checkboxes-checkbox');
    var optionId;
    if (checkboxes.length > 0) {
        productOption = [];
        optionId = optionChanged.closest('.product-option').find('.product-option-id').val();
        optionChanged.closest('.product-option').find('.checkboxes-checkbox:checked').each(function () {
            productOption.push($(this).attr('name').replace('product-options[' + optionId + '][', '').replace(']', ''));
        });
    }
    var radioImageCheckboxes = optionChanged.closest('.product-option').find('.radio-image-radio[type=checkbox]');
    if (radioImageCheckboxes.length > 0) {
        productOption = [];
        optionId = optionChanged.closest('.product-option').find('.product-option-id').val();
        optionChanged.closest('.product-option').find('.radio-image-radio:checked').each(function () {
            productOption.push($(this).attr('name').replace('product-options[' + optionId + '][', '').replace(']', ''));
        });
    }
    var fpd_product_price = '';
    if ($('input[name=fpd_product_price]').length > 0) {
        fpd_product_price = $('input[name=fpd_product_price]').val();
    }
    var attributes = {};
    summary.find('.variations').find('input, select').each(function () {
        attributes[$(this).attr('name')] = $(this).val();
    });
    var post_id = optionChanged.closest('.product-options').find('.product-post-id').val();
    var product_option_post_id = optionChanged.closest('.product-option').find('.product-option-post-id').val();
    var product_option_id = optionChanged.closest('.product-option').find('.product-option-id').val();
    var variation_id = 0;
    if (summary.find('input[name="variation_id"]').length > 0) {
        variation_id = summary.find('input[name="variation_id"]').val();
    }
    optionChanged.find('.product-option-error').remove();
    jQuery.post(woocommerce_product_options_settings.ajaxurl,
      {
          'action': 'update_product_option',
          'post_id': post_id,
          'variation_id': variation_id,
          'product_option_post_id': product_option_post_id,
          'product_option': productOption,
          'product_option_id': product_option_id,
          'attributes': attributes,
          fpd_product_price: fpd_product_price
      },
      function (response) {
        response = $.parseJSON(response);
        amountToUpdate.html(response.price);
        optionPriceToUpdate.html(response.option_price);
        if (response.src != '') {
            optionChanged.find('.upload-image').attr('src', response.src);
        }
        $('form.cart button').prop("disabled", false);
        $('form.cart button').css('opacity', '1');
        $('.product-option, .variations').find('input, select, textarea').prop("disabled", false);
        $('.product-option').find('input, select, textarea').css('opacity', '1');
        $('.variations').find('.value, .product_value, .product-value').css('opacity', '0.5');
        callback();
      }
    );
  }

  function buttonStatus(enable) {
    enable = (enable == undefined) ? true : enable;
    if (enable) {
        jQuery(button).removeClass("processing").removeAttr("disabled");
    }
    else {
        jQuery(button).addClass("processing").attr("disabled", true).siblings(".alert-danger").remove();
    }
  }

  if (quantity == undefined) {
    quantity = 1;
  }

  if (nameOfItems == undefined) {
    nameOfItems = "tickets";
  }

  var button = event.currentTarget;
  if (button.tagName == "FORM") {
    button = jQuery(button).find('[type="submit"]')[0];
  }
  buttonStatus(false);

  function formValidationMessage(msg, success) {
      var alertClass = success ? "alert-success" : "alert-danger";
      var message = jQuery('<div class="col-12 ' + alertClass + '">' + msg + '</div>');
      message.insertAfter(button);
      buttonStatus(true);
  }

  // get array of all numbers of tickets selected for each option
  var options = [].map.call(jQuery(button).siblings(".summary").find("input.product-option-value"), function(opt) {
      return +opt.value;
  });
  if (options.length) {
    // flag error if no tickets selected, or if there are any negative numbers entered
    if (!options.filter(function(val) {
        return val != 0;
    }).length || options.filter(function(val) {
        return val < 0;
    }).length) {
        formValidationMessage("Please select a valid number of " + nameOfItems, false);
        return;
    }
  }

  if (quantity < 1) {
    formValidationMessage("Please select a valid number of " + nameOfItems, false);
    return;
  }

  var select = jQuery(button).closest(".sub-content").find('.product-option-select');

  select.each(function () { // hack, only do for select because that's the only field we're worried about,
    //the pickup point (the other choices are already sent through to the function)
    ajax_update_option($(this).find("select"), do_add_to_cart);
  });

  if (!select.length) {
    do_add_to_cart();
  }

  function do_add_to_cart() {
    jQuery.ajax({
        url: wc_add_to_cart_params.ajax_url,
        type: "post",
        data: {
            action: "woocommerce_ajax_add_to_cart",
            product_id: productId,
            quantity: quantity
        },
        success: function(response) {
            if (response.error) {
                formValidationMessage("Something unexpected went wrong, please try again", false);
            }
            else {
                formValidationMessage('Successfully added to your <a href="/basket">basket', true);
            }
            buttonStatus(true);
        },
        error: function() {
            formValidationMessage("Something unexpected went wrong, please try again", false);
            buttonStatus(true);
        }
    });
  }
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var searchBar = $('.search-bar');

        $('.burger-icon').click(function() {
            openNav();
        });

        jQuery('.burger-icon-blue').click(function() {
          closeNav();
        });

        $('.search-box').click(function(e) {
          e.preventDefault();
          e.stopPropagation();
          var minHeight = searchBar.css('min-height');

          searchBar.css('min-height',0).slideToggle("medium", function() {
            $(this).css('min-height', minHeight);
            if ($(this).is(":visible")) {
              $("#search-bar-field").focus();
            }
          });
        });

        // close search box (if open) on outside click
        $("html").on("click", function() {
          var minHeight = searchBar.css('min-height');
          if (searchBar.is(":visible")) {
            searchBar.css('min-height',0).slideToggle("medium", function() {
              $(this).css('min-height', minHeight);
              if ($(this).is(":visible")) {
                $("#search-bar-field").focus();
              }
           });
          }
        });

        searchBar.on("click", function(e) {
          e.stopPropagation();
        });

        // add blue contact section to bottom of mobile menu (can't alter the MegaMenu html directly):

        var infoWrapper = $('<div class="mobile-info-wrapper">');
        infoWrapper.append($('<p>Stanley: <a href="tel:01207237424">01207 237 424</a></p>'));
        infoWrapper.append($('<p>Newcastle: <a href="tel:01912423232">0191 242 3232</a></a></p>'));
        infoWrapper.append($('<p>Durham: <a href="tel:01913832905">0191 383 2905</a></p>'));
        infoWrapper.append($('<p>Email: <a href="mailto:sales@stanley-travel.com">sales@stanley-travel.com</a></p>'));
        infoWrapper.append($('<div class="icon"><a href="/basket"><img src="/wp-content/uploads/2019/11/unhovered-orange-basket-box-icon.png"></a></div>'));
        $("#mega-menu-main-header-menu").append(infoWrapper);

        // mobile sub-nav open/close:
        $(".mobile-nav h3").click(function() {
          $(this).next("nav.mobile-sub-menu").slideToggle();
        });

        $(".match-height").matchHeight();
        $(".match-height-2").matchHeight();
        $('.item-matchheight').matchHeight();
        $('.button-matchheight').matchHeight();

        // new menu, sticky effect
        $(window).on("load scroll resize orientationchange", function() {
          var theMenu = $("#main-menu");
          var menuHeight = theMenu.height();
          var switchHeight = 1.5 * menuHeight;
          if ($(window).scrollTop() > switchHeight) {
            theMenu.addClass("stick-to-top");
          }
          else {
            theMenu.removeClass("stick-to-top");
          }
        });

        // change menu event trigger based on screen size
        $(window).on("load resize orientationchange", function() {
          var breakPoint = 1183; // should be 1200, but in practice this seems to be needed
          var menu = $("#mega-menu-main-header-menu");
          if ($(window).width() >= breakPoint) {
            if (menu.attr("data-event") !== "hover_intent") {
              menu.attr("data-event", "hover_intent");
              menu.removeData("maxmegamenu");
              menu.maxmegamenu();
            }
          }
          else {
            if (menu.attr("data-event") !== "click") {
              menu.attr("data-event", "click");
              menu.removeData("maxmegamenu");
              menu.maxmegamenu();
            }
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
